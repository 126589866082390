import 'primeflex/primeflex.css'
import 'primevue/resources//themes/saga-blue/theme.css' //theme
import 'primeicons/primeicons.css' //icons
import 'primevue/resources/primevue.min.css' //core css
import '../public/resources/theme/theme.css' //custom theme css

import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import SplitButton from 'primevue/splitbutton'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import InputText from 'primevue/inputtext'
import Card from 'primevue/card'
import Divider from 'primevue/divider'
import Paginator from 'primevue/paginator'
import Calendar from 'primevue/calendar'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'
import ConfirmationService from 'primevue/confirmationservice'
import ConfirmDialog from 'primevue/confirmdialog'
import Dialog from 'primevue/dialog'
import SelectButton from 'primevue/selectbutton'
import Checkbox from 'primevue/checkbox'
import Dropdown from 'primevue/dropdown'
import Chart from 'primevue/chart'
import InputMask from 'primevue/inputmask'
import InputNumber from 'primevue/inputnumber'
import pt from './locales/pt.json'
import Message from 'primevue/message'

export default {
	install(app) {
		app.use(PrimeVue, {
			locale: pt.prime
		})

		app.use(ToastService)
		app.use(ConfirmationService)

		app.component('Button', Button)
		app.component('SplitButton', SplitButton)
		app.component('Column', Column)
		app.component('DataTable', DataTable)
		app.component('TabView', TabView)
		app.component('TabPanel', TabPanel)
		app.component('InputText', InputText)
		app.component('Card', Card)
		app.component('Divider', Divider)
		app.component('Paginator', Paginator)
		app.component('Paginaor', Card)
		app.component('Calendar', Calendar)
		app.component('Toast', Toast)
		app.component('Dialog', Dialog)
		app.component('SelectButton', SelectButton)
		app.component('Checkbox', Checkbox)
		app.component('Dropdown', Dropdown)
		app.component('InputMask', InputMask)
		app.component('InputNumber', InputNumber)
		app.component('ConfirmDialog', ConfirmDialog)
		app.component('Chart', Chart)
		app.component('Message', Message)
	}
}
