<template>
	<div class="stepper-wrapper">
		<div
			v-for="(item, index) in items"
			:key="item.label"
			:class="`stepper-item ${
				finished ? 'finished' : currentStep == index ? 'current' : currentStep >= index ? 'completed' : 'none'
			}`"
		>
			<div :class="`dot ${currentStep >= index ? 'completed' : ''}`"></div>
			<div class="step-name">{{ item.label }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Steps',
	props: {
		currentStep: Number,
		items: Array,
		finished: Boolean
	}
}
</script>

<style scoped>
.stepper-wrapper {
	position: relative;
	top: 20px;
	display: flex;
	justify-content: space-around;
	flex: 0.15;
}

.stepper-item {
	display: flex;
	flex: 1;
	justify-content: center;
	border-top: 3px solid;
}

.stepper-item.none {
	border-image: linear-gradient(to right, var(--grey-100) 0%, var(--grey-100) 50%) 100% 1;
}

.stepper-item.current {
	border-image: linear-gradient(to right, var(--lime) 50%, var(--grey-100) 50%) 100% 1;
}

.stepper-item.completed {
	border-image: linear-gradient(to right, var(--lime) 100%, var(--grey-100) 50%) 100% 1;
}

.stepper-item.finished {
	border-image: linear-gradient(to right, var(--lime) 100%, var(--grey-100) 50%) 100% 1;
}

.dot {
	width: 27px;
	height: 15px;
	background-color: var(--grey-100);
	position: relative;
	transform: translate(140%, -60%);
	border: 3px solid var(--backgroud-color);
	border-radius: 16px;
}

.dot.completed {
	background-color: var(--lime);
}

.step-name {
	margin-top: 6px;
}
</style>
