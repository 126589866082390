<template>
	<Toast position="top-center">
		<template #message="slotProps">
			<div class="p-d-flex p-flex-row p-jc-center p-ai-center">
				<img
					v-if="slotProps.message.severity === 'success'"
					class="sucess-image"
					src="/resources/icons/toast_success.svg"
					@click="handleNewClient"
				/>
				<img
					v-if="slotProps.message.severity === 'error'"
					class="sucess-image"
					src="/resources/icons/toast_error.svg"
					@click="handleNewClient"
				/>
				<p>{{ slotProps.message.detail }}</p>
			</div>
		</template>
	</Toast>

	<div class="main-wrapper">
		<div class="sidebar">
			<LeftMenu></LeftMenu>
		</div>
		<div id="content">
			<router-view />
		</div>
	</div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu'
export default {
	name: 'App',
	components: {
		LeftMenu
	}
}
</script>

<style>
html {
	height: 100%;
	box-sizing: border-box; /* https://css-tricks.com/box-sizing/ */
}

*,
*:before,
*:after {
	box-sizing: inherit; /* https://css-tricks.com/box-sizing/ */
}
body {
	height: 100%;
	margin: 0;
}

#app {
	font-family: Azo Sans;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

#content {
	flex: 8;
}

.sidebar {
	width: 320px;
	flex: 1;
}

.main-wrapper {
	display: flex;
	min-height: 100vh;
}

.sucess-image {
	height: 30px;
	margin-right: 20px;
}
</style>
