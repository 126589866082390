import Keycloak from 'keycloak-js'
export let initOptions = {
	url: process.env.VUE_APP_LOGIN_URL + '/auth/',
	realm: 'backoffice',
	clientId: 'account',
	onLoad: 'login-required'
}

export const keycloakInstance = Keycloak(initOptions)

export default keycloakInstance
