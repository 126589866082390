<template>
	<div id="sidenav">
		<div>
			<div class="logo-wrapper">
				<img class="logo-image" src="/resources/images/white_logo.svg" alt="logo" />
				<h2 class="logo-title">{{ $t('appName') }}</h2>
			</div>

			<router-link
				to="/clientes"
				id="left-menu-link-clientes"
				:class="`menu-link ${isSelected('/clientes') ? 'selected' : ''}`"
			>
				<img class="menu-icon" src="/resources/icons/doc-outlined.svg" alt="accounts" />
				{{ $t('leftMenu.accounts') }}
			</router-link>
			<router-link
				id="left-menu-link-tarifarios"
				to="/tarifarios"
				:class="`menu-link ${isSelected('/tarifarios') ? 'selected' : ''}`"
			>
				<img class="menu-icon" src="/resources/icons/sms.svg" alt="products" />
				{{ $t('leftMenu.products') }}
			</router-link>
		</div>
		<div class="p-d-flex p-flex-column">
			<span class="p-d-flex p-jc-start username">{{ auth.preferred_username }}</span>
			<Divider class="color-grey" />
			<Button
				label="Sair da sessão"
				icon="pi pi-arrow-right"
				iconPos="right"
				class="logout-button"
				@click="logout"
				id="left-menu-logout"
			>
				<span>Sair da sessão</span>
				<img class="menu-icon" src="/resources/icons/logout.svg" alt="logout" />
			</Button>
		</div>
	</div>
</template>

<script>
// import Auth from "@/shared/services/auth";

import AuthApp from '../main'

export default {
	data: function () {
		return {
			auth: AuthApp
		}
	},
	methods: {
		logout() {
			this.auth.logout()
		},
		isSelected(path) {
			return this.$route.path.includes(path)
		}
	}
}
</script>

<style lang="scss">
#sidenav {
	background-color: #2a2a33;
	color: #ffffff;
	height: 100%;
	width: 100%;
	z-index: 1;

	overflow-x: hidden;
	padding-top: 20px;
	display: flex;
	flex-direction: column;
	text-align: left;

	justify-content: space-between;

	a {
	}
}

.logo-wrapper {
	display: flex;
	align-content: center;
	align-items: center;
	gap: 8px;
	line-height: 32px;
	padding: 20px;
}

.logo-image {
	width: 74px;
	height: 74px;
}

.logo-title {
	line-height: 34px;
	font-size: 24px;
}
.menu-icon {
	width: 20px;
	height: 20px;
}

.menu-link {
	display: flex;
	gap: 12px;
	height: 56px;
	padding: 8px;
	padding-left: 20px;
	align-items: center;
	color: #ffffff;
	font-size: 16px;
	line-height: 24px;
	text-decoration: none;
}

.menu-link.selected {
	background-color: var(--grey-600);
}

.logout-button {
	display: flex;
	justify-content: space-between;
}

.username {
	margin-left: 10px;
	color: var(--grey-400);
	font-style: italic;
}
</style>
