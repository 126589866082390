import keycloakInstance, { initOptions } from '../../keycloak'
import jwt_decode from 'jwt-decode'

class Auth {
	constructor(initVue, loading) {
		this.initAuthApp(initVue, loading)
	}

	setToken() {
		if (keycloakInstance.token) {
			this.keycloakInstance = keycloakInstance
			this.token = keycloakInstance.token
			this.decoded = jwt_decode(keycloakInstance.token)
			this.role = this.decoded?.mpro.role
			this.permissions = this.decoded?.permissions?.auth
			this.email_verifie = this.decoded?.email_verified
			this.preferred_username = this.decoded.preferred_username
		}
	}

	guardRoutes(to, _from, next) {
		const { authorize } = to.meta

		if (authorize) {
			if (!authorize.includes(this.role)) {
				return next({ path: '/' })
			}
		}

		next()
		// const guard = guardRoles[to.name];
		// if ((guard && roles.includes(guard)) || guard === "*") {
		//   next();
		// }
	}

	initAuthApp(initVue, loading) {
		loading.mount('#loading')
		keycloakInstance
			.init({ onLoad: initOptions.onLoad })
			.then((auth) => {
				if (!auth) {
					window.location.reload()
				} else {
					this.setToken()
					initVue()
				}
				//Token Refresh
				setInterval(() => {
					keycloakInstance
						.updateToken(120)
						.then((refreshed) => {
							if (refreshed) {
								this.setToken()
							}
						})
						.catch((error) => {
							console.error('Failed to refresh token:' + error)
						})
				}, 20000)
			})
			.catch((err) => {
				console.error('Authenticated Failed', err)
				document.getElementById('loading').style.display = 'none'
				window.location.href = '/error.html'
			})
	}

	logout() {
		if (this.keycloakInstance) {
			this.keycloakInstance.logout()
		}
	}
}

export default Auth
