import { createWebHistory, createRouter } from 'vue-router'

import AuthApp from './main'

import { defineAsyncComponent } from 'vue'

const Accounts = defineAsyncComponent(() => import('@/views/Accounts.vue'))
const Home = defineAsyncComponent(() => import('@/views/Home.vue'))

const Products = defineAsyncComponent(() => import('@/views/Products.vue'))

const Account = defineAsyncComponent(() => import('@/views/Account.vue'))
const AccountStatistics = defineAsyncComponent(() => import('@/views/AccountStatistics.vue'))
const AccountBilling = defineAsyncComponent(() => import('@/views/AccountBilling.vue'))
const AccountHistory = defineAsyncComponent(() => import('@/views/AccountHistory.vue'))
const AccountPayments = defineAsyncComponent(() => import('@/views/AccountPayments.vue'))

const NewProduct = defineAsyncComponent(() => import('@/views/Product.vue'))

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/clientes',
		name: 'clientes',
		component: Accounts
	},
	{
		path: '/clientes/novo',
		name: 'novoCliente',
		component: Account,
		meta: {
			authorize: ['bo_administrator', 'bo_account_manager']
		}
	},
	{
		path: '/clientes/:id',
		name: 'editClient',
		component: Account
	},
	{
		path: '/clientes/:id/statistics',
		name: 'clientStatistics',
		component: AccountStatistics
	},
	{
		path: '/clientes/:id/billing',
		name: 'clientBilling',
		component: AccountBilling
	},
	{
		path: '/clientes/:id/history',
		name: 'clientHistory',
		component: AccountHistory
	},
	{
		path: '/clientes/:id/payments',
		name: 'clientPayments',
		component: AccountPayments
	},
	{
		path: '/tarifarios',
		name: 'tarifarios',
		component: Products
	},
	{
		path: '/tarifarios/:id',
		name: 'tarifario',
		component: NewProduct
	},
	{
		path: '/tarifarios/novo',
		name: 'novoTarifario',
		component: NewProduct,
		meta: {
			authorize: ['bo_administrator']
		}
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

router.beforeEach((...props) => AuthApp.guardRoutes(...props))

export default router
